@import "../../../css/vars.scss";
@import "../../../css/mixin.scss";

.book-appointment-section {
    position: relative;
    overflow: hidden;
    .notification-container {
        &.top-right {
            top: 0;
            right: 0;
        }
    }
    .appointment-book-list-wrapper {
        @include flexbox();
        @include flex-wrap(wrap);
        margin-left: -1.6rem;
    }
    .sub-title {
        font-size: $font-size-xl;
        font-weight: $font-semi-bold;
        color: $heading-color;
        line-height: 2.5rem;
        margin: 2.5rem 0;
        span {
            font-weight: $font-bold;
        }
    }
}
