@import "../../css/vars.scss";
@import "../../css/mixin.scss";
@import "../../css/breakpoints.scss";

.profile-wrap {
    @include flexbox();
    @include align-items(center);
    color: $heading-color;
    font-size: $font-size-ml;
    font-weight: $font-semi-bold;
    line-height: 2.2rem;
    .img-wrap {
        background: linear-gradient(0deg, #dfe2ec, #dfe2ec);
        border-radius: $border-radius-base + 0.8rem;
        width: 8.0rem;
        height: 8.0rem;
        overflow: hidden;
        margin-right: $gap-16;
        @include md {
            margin-right: 1.2rem;
        }
        img{
            max-width: 100%;
        }
    }
    .profile-text {
        p {
            &:first-child {
                margin-bottom: 4px;
            }
        }
    }
}
