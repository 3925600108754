@import "../../css/vars.scss";
@import "../../css/mixin.scss";

.user-image-wrap {
    @include flexbox();
    @include align-items(center);
    color: $heading-color;
    font-size: $font-size-ml;
    font-weight: $font-semi-bold;
    line-height: 2.2rem;
    .img-wrap {
        background: linear-gradient(0deg, #dfe2ec, #dfe2ec);
        border-radius: $border-radius-base + 0.8rem;
        min-width: 8rem;
        min-height: 8rem;
        overflow: hidden;
        margin-right: $gap-16;
        img {
            max-width: 100%;
        }
    }
}
