$breakpoints:(
    xs: '480px',
    sm: '576px',
    md: '767px',
    lg: '991px',
    xlg: '1024px',
    xl: '1200px',
    xxl: '1600px',
    xxxl: '1920px',
);

@mixin xs {
    @media(max-width: map-get($breakpoints, "xs")){
        @content;
    }
}
@mixin sm {
    @media(max-width: map-get($breakpoints, "sm")){
        @content;
    }
}
@mixin md {
    @media(max-width: map-get($breakpoints, "md")){
        @content;
    }
}
@mixin lg {
    @media(max-width: map-get($breakpoints, "lg")){
        @content;
    }
}
@mixin xlg {
    @media(max-width: map-get($breakpoints, "xlg")){
        @content;
    }
}
@mixin xl {
    @media(max-width: map-get($breakpoints, "xl")){
        @content;
    }
}
@mixin xxl {
    @media(max-width: map-get($breakpoints, "xxl")){
        @content;
    }
}
@mixin xxxl {
    @media(max-width: map-get($breakpoints, "xxxl")){
        @content;
    }
}

@mixin breakpoint($bp: 0){
    @media(max-width: $bp){
        @content;
    }
}