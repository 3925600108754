@import "../../css/mixin.scss";
@import "../../css/vars.scss";
@import "../../css/breakpoints.scss";

.appointment-book-list-wrap {
    background: $color-white;
    box-shadow: 0px 1px 4px rgba(19, 22, 32, 0.08);
    border-radius: $gap-08;
    padding: 2.4rem;
    @include flex(1);
    margin-left: $gap-16;
    margin-bottom: $gap-16;
    @include md {
        padding: 1.6rem;
    }
    &:nth-child(3){
        margin-right: 0;
    }
    &:last-child{
        margin-right: 0;
    }
    .user-profile-wrap {
        @include flexbox();
        @include align-items(flex-start);
        .img-area {
            width: 7rem;
            height: 7rem;
            overflow: hidden;
            border-radius: $border-radius-base;
            img{
                max-width: 100%;
            }
        }
        .user-details {
            margin-left: 1.6rem;
            color: $heading-color;
            .name {
                font-size: 2.8rem;
                font-weight: $font-bold;
                line-height: 3.5rem;
                margin-bottom: 0.4rem;
                @include md {
                    font-size: 2.0rem;
                    line-height: 2.5rem;
                }
            }
            .details {
                font-size: 2rem;
                font-weight: $font-medium;
                line-height: 2.5rem;
            }
        }
    }
    .location-details{
        .location{
            color: $heading-color;
            font-size: $font-size-heading;
            line-height: 1.8rem;
            margin-top: 3.0rem;
        }
        .time{
            @extend .location;
            margin-top: 2.4rem;
        }
    }
    .card-footer {
        background: rgba(239, 241, 245, 0.3);
        box-shadow: 0px 1px 4px rgba(19, 22, 32, 0.08);
        border-radius: 0.8rem;
        height: 6.0rem;
        width: calc(100% + 4.8rem);
        margin-left: -2.4rem;
        margin-bottom: -2.4rem;
        @include flexbox();
        @include justify-content(center);
        @include align-items(center);
        @include md {
            height: 7.8rem;
            box-shadow: none;
        }
        margin-top: 2.4rem;
        button{
            padding: 0 3rem;
        }
        .checkbox-wrap {
            .active-check {
                background: url("../../img/checkbox-circle.png");
                background-size: cover;
                width: 2.5rem;
                height: 2.5rem;
                position: relative;
                margin-left: -8.2rem;
                span{
                    position: absolute;
                    left: 3.4rem;
                    width: 162px;
                    top: -0.5rem;
                    color: $light-blue;
                    font-size: 2rem;
                    font-weight: $font-semi-bold;
                }
            }
        }
    }
    &.active {
        .card-footer {
            .checkbox-wrap {
                .active-check {
                    background: url("../../img/checkbox-circle-active.png");
                    background-size: cover;
                    width: 2.5rem;
                    height: 2.5rem;
                }
            }
        }
    }
}
