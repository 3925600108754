@import "../../css/sprite-positions.scss";
@import "../../css/mixin.scss";
@import "../../css/vars.scss";
@import "../../css/breakpoints.scss";

.payment-wrap {
    background: $light-gray;
    box-shadow: -10px -10px 30px #ffffff, 10px 10px 30px rgba(223, 226, 236, 0.4);
    border-radius: $border-radius-base + 0.8rem;
    padding: 2rem 1.6rem;
    @include md {
        padding: 2rem 0.8rem;
    }
    .payment-main-wrap {
        @include flexbox();
        @include justify-content(space-between);
        @include md {
            display: block;
        }
        .payment-plan-wrap {
            // width: 53%;
            @include md {
                width: 100%;
            }
            .carousel-img {
                .slick-dots {
                    @include md {
                        display: none !important;
                    }
                }
            }
            h2 {
                margin-bottom: 1.6rem;
                @include md {
                    margin-top: 2.4rem;
                }
            }
            .payment-plan-wrap-in {
                @include flexbox();
                @include justify-content(flex-end);
                background: url("../../img/payments-bg.png");
                background-repeat: no-repeat;
                .payment-plan-wrap-in-desktop {
                    @include flexbox();
                    @include md {
                        display: block;
                    }
                }
                @include md {
                    display: block;
                }
                .plan-card {
                    margin-right: 1.4rem;
                    &:nth-child(3) {
                        margin-right: 0;
                    }
                }
            }
            .payment-plan-bg {
                padding: 1.6rem 3.5rem;
                background: url("../../img/payment-plan-m-bg.png");
                background-size: cover;
                .carousel-img {
                    width: calc(100% - 3.5rem);
                    margin: 0 auto;
                    .ant-carousel {
                        .slick-prev {
                            left: -3.5rem;
                        }
                        .slick-next{
                            right: -3.5rem;
                        }
                    }
                    .plan-card{
                        min-height: 30.5rem;
                        @include justify-content(center);
                    }
                }
            }
        }
        .current-balance-section {
            // width: 44%;
            margin-right: 24px;
            @include md {
                width: 100%;
            }
            h2 {
                margin-bottom: 1.6rem;
                @include md {
                    margin-bottom: 1.2rem;
                }
            }
        }
    }
    .separator {
        background: $secondary-color;
        height: 2px;
        width: calc(100% - 22rem);
        margin: 4rem 0 4rem 11rem;
        @include md {
            width: 100%;
            margin: 4rem 0 4rem 0rem;
            border-bottom: 0.2rem dotted $secondary-color;
            background: transparent;
        }
    }
    .receipt-table-wrap {
        padding: 2.4rem;
        background: $color-white;
        border-radius: $gap-08;
        @include md {
            padding: 2.4rem 0.8rem;
        }
        .receipt-table-heading {
            @include flexbox();
            @include justify-content(space-between);
            @include align-items(center);
            margin-bottom: 2.4rem;
            @include md {
                padding: 0 1.2rem;
            }
        }
        .table-wrap {
            @include md {
                width: 100%;
                overflow: auto;
            }
        }
    }
}
