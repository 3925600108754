@import "../../css/vars.scss";
@import "../../css/mixin.scss";
@import "../../css/breakpoints.scss";



.content-wrap{
    padding: 12.4rem 1.6rem 1.6rem 14.0rem;
    width: calc(100% - 53.0rem);
    @include xlg {
        width: 100%;
        padding: 12.4rem 1.6rem 1.6rem 1.6rem;
        @include md {
            padding: 6rem 1.6rem 1.6rem 1.6rem;
        }
    }
    .welcome-section{
        margin: -6px 0px;
        h3{
            font-weight: $font-medium;
        }
    }
    .demographic-insurance-section{
        margin-top: 80px;
        .ant-collapse-content {
            background: transparent;
            & > .ant-collapse-content-box{
                padding-left: 0;
                padding-right: 0;
            }
        }
        .ant-collapse{
            background: transparent;
            border: none;
            & > .ant-collapse-item{
                border-bottom: none;
                margin-bottom: 16px;
                .ant-collapse-header{
                    color: $primary-color;
                    font-size: $font-size-ml;
                    padding-left: 0;
                    text-transform: uppercase;
                    font-weight: $font-medium;
                    span{
                        display: none;
                    }
                }
            }
        }
    }
    .demographic-section{
        margin-top: 60px;        
        h4{
            color: $primary-color;
            margin-bottom: 30px;
        }
    }
    .insurance-section{
        margin-top: 60px;
        h4{
            color: $primary-color;
            margin-bottom: 30px;
        }
    }
}