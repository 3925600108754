@import "../../../css/mixin.scss";
@import "../../../css/vars.scss";
@import "../../../css/breakpoints.scss";

.custom-group-input {
    margin-bottom: 8px;
    @include md {
        margin-bottom: 16px;
    }
    &.inline-field {
        @include flexbox();
        label {
            width: 100%;
            font-size: $font-size-ml;
            line-height: 2.3rem;
        }
    }
    input {
        border-radius: 0.8rem;
        font-size: $font-size-ml;
        line-height: 2.3rem;
        background: $color-white;
        padding: 1.2rem;
        border: none;
        color: #8e9abb;
        outline: none;
        &.extension {
            background: linear-gradient(128deg, #ffffff -79.65%, #ffffff 151.25%);
            border: 1px solid #eff1f5;
            box-shadow: 4px 3px 19px #eff1f5;
            border-radius: 8px !important;
            z-index: 1;
            width: calc(20% + 8px) !important;
            & + input{
                padding-left: 2rem;
                z-index: 0;
                border-radius:  0 0.8rem 0.8rem 0 !important;
                &:hover{
                    z-index: 0;
                }
                &:focus{
                    box-shadow: none;
                }
            }
        }
        @include md {
            font-size: 1.6rem;
        }
    }
    &.bold-type {
        input {
            font-weight: $font-bold !important;
        }
    }
    .ant-input-group.ant-input-group-compact > *:not(:last-child){
        margin-right: -6px;
    }
    .ant-input-group.ant-input-group-compact{
        @include flexbox();
    }
}
