@import "../../css/vars.scss";
@import "../../css/mixin.scss";
@import "../../css/breakpoints.scss";

button {
    &.btn {
        &.primary {
            min-width: 20rem;
            min-height: 6rem;
            background: $secondary-color;
            border-radius: $border-radius-base + 0.6rem;
            border: none;
            color: $color-white;
            font-size: $font-size-xxl;
            font-weight: $font-semi-bold;
            text-transform: capitalize;
            cursor: pointer;
            @include md {
                min-width: 15rem;
            }
            &.arrow {
                min-height: 4.8rem;
                span {
                    display: inline-block;
                    padding-right: 3rem;
                    &::before {
                        content: "";
                        background-position: -269px -1182px;
                        width: 24px;
                        height: 24px;
                        position: absolute;
                        top: calc(50% - 12px);
                        right: 0;
                    }
                    @include xxl {
                        &::before {
                            background-position: -217px -982px;
                            background-size: 1600px;
                        }
                    }
                    @include xlg {
                        &::before {
                            top: calc(50% - 14px);
                            right: -4px;
                        }
                    }
                }
            }
        }
        &.outline {
            @extend .primary;
            background: transparent;
            border: 1px solid $secondary-color;
            color: $heading-color;
            &.arrow {
                min-height: 4.8rem;
                span {
                    display: inline-block;
                    padding-right: 3rem;
                    &::before {
                        content: "";
                        background-position: -202px -1180px;
                        width: 24px;
                        height: 24px;
                        position: absolute;
                        top: calc(50% - 12px);
                        right: 0;
                    }
                    @include xxl {
                        &::before {
                            background-position: -163px -982px;
                            background-size: 1600px;
                        }
                    }
                    @include xlg {
                        &::before {
                            top: calc(50% - 14px);
                            right: -4px;
                        }
                    }
                }
            }
            &.active {
                background: $secondary-color;
                color: $color-white;
            }
        }
        &.link {
            border: none;
            padding: 0;
            height: auto;
        }
        &.appointment-btn {
            width: 16.7rem;
            height: 2.8rem;
            background: $light-sky;
            border: 1px solid $light-sky;
            border-radius: $border-radius-base;
            color: $secondary-color-dark;
            font-size: $font-size-base;
            font-weight: $font-semi-bold;
            line-height: 1.8rem;
            outline: none;
            cursor: pointer;
            &.warning {
                background: rgba(237, 91, 117, 0.2);
                border: 1px solid rgba(237, 91, 117, 0.1);
                color: $color-warning;
            }
        }
        &.download-btn {
            background: $light-sky;
            min-height: 2.8rem;
            height: auto;
            border-radius: $border-radius-base;
            border: 1px solid $light-sky-2;
            color: $secondary-color-dark;
            font-size: $gap-14;
            font-weight: $font-semi-bold;
            line-height: 1.8rem;
            padding: 0 3.2rem 0 0.8rem;
            cursor: pointer;
            position: relative;
            &.upload-file-btn{
                padding: 0 4.0rem 0 0.8rem;
                min-width: 12.8rem;
            }
            @include md {
                text-indent: -999999px;
            }
            &::before {
                content: "";
                background-position: -398px -115px;
                width: 1.6rem;
                height: 1.6rem;
                position: absolute;
                top: calc(50% - 0.8rem);
                right: 0.9rem;
            }
            @include xxl {
                top: -2px;
                &::before {
                    content: "";
                    background-position: -269px -78px;
                    background-size: 1300px;
                    width: 1.8rem;
                    height: 1.8rem;
                }
            }
            @include md {
                top: -2px;
                &::before {
                    content: "";
                    background-position: -398px -115px;
                    background-size: 1920px;
                    width: 1.6rem;
                    height: 1.6rem;
                }
            }
        }
    }
}
