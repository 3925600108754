@import "../../css/mixin.scss";
@import "../../css/vars.scss";
@import "../../css/breakpoints.scss";

.upload-wrap {
    @include flexbox();
    @include align-items(center);
    margin: 1.6rem 0;
    .ant-upload {
        &.ant-upload-select {
            display: block;
            .ant-upload {
                @include flexbox();
                @include align-items(center);
                p {
                    font-size: $font-size-l;
                    color: $heading-color;
                    font-weight: $font-medium;
                    margin-left: 1.6rem;
                    margin-top: -3px;
                    min-width: 12.4rem;
                }
            }
        }
    }
    .ant-upload-list {
        &.ant-upload-list-text {
            font-size: $font-size-l;
            color: $heading-color;
            font-weight: $font-medium;
            width: 168px;
        }
        .ant-upload-list-item {
            font-size: $font-size-l;
            color: $heading-color;
        }
    }
}
