@import "../../css/mixin.scss";
@import "../../css/vars.scss";

.medicine-card-section {
    margin-bottom: $gap-16;
    .medicine-card {
        background: rgba(223, 226, 236, 0.2);
        border: 0.770559px solid rgba(223, 226, 236, 0.1);
        border-radius: $border-radius-base + 0.8rem;
        padding: 2rem 2.8rem 2rem 2.4rem;
        @include flexbox();
        @include justify-content(space-between);
        @include align-items(center);
        cursor: pointer;
        &.active {
            .checkbox-wrap {
                .active-check {
                    background: url("../../img/checkbox-circle-active.png");
                    background-size: cover;
                    width: 2.5rem;
                    height: 2.5rem;
                }
            }
        }
        .medication-card-wrap {
            @include flexbox();
            .img-area {
                background: $light-gray;
                border: 0.7px solid $color-white;
                box-shadow: 1.5px 1.5px 3px rgba(174, 174, 192, 0.4);
                border-radius: $border-radius-base + 0.4rem;
                width: 4.8rem;
                height: 4.8rem;
                overflow: hidden;
                margin-right: 16px;
                img {
                    max-width: 100%;
                }
            }
            .user-details {
                font-size: $font-size-ml;
                .name {
                    font-weight: $font-bold;
                    line-height: 2.2rem;
                    margin-bottom: 4px;
                }
                .details {
                    line-height: 2.3rem;
                    .dose {
                        font-weight: $font-semi-bold;
                        margin-right: 1.6rem;
                        line-height: 2.3rem;
                    }
                    .WhenToTake {
                        font-size: $font-size-l;
                    }
                }
            }
        }
        .checkbox-wrap {
            .active-check {
                background: url("../../img/checkbox-circle.png");
                background-size: cover;
                width: 2.5rem;
                height: 2.5rem;
            }
        }
    }

    .instructions {
        color: $gray-border;
        font-size: $font-size-base;
        font-weight: $font-semi-bold;
        line-height: 1.8rem;
        margin-top: $gap-09;
    }
}
