@import "../../css/mixin.scss";
@import "../../css/vars.scss";

.pressure-section {
    background: linear-gradient(128deg, #ffffff -79.65%, #ffffff 151.25%);
    box-shadow: -7px -7px 16px #fafbfc, 4px 3px 19px #eff1f5;
    border-radius: $border-radius-base + 1.6rem;
    padding: $gap-16;
    position: relative;
    .pressure-graph{
        margin-left: 2.2rem;
    }
    h2{
        margin-bottom: 2.4rem;
        padding-left: 9.6rem;
        height: 8rem;
        @include flexbox();
        @include align-items(center);
        font-weight: $font-semi-bold;
        &.pressure-title{
            &::before{
                content: '';
                background: url('../../img/vision2.png');
                width: 8.0rem;
                height: 8.0rem;
                background-size: cover;
                position: absolute;
                left: 1.6rem;
                top: 1.6rem;
            }
        }
    }
}

