@import "../../../css/mixin.scss";
@import "../../../css/vars.scss";
@import "../../../css/breakpoints.scss";

.custom-text-input {
    margin-bottom: 8px;
    @include md {
        margin-bottom: 16px;
    }
    &.inline-field {
        @include flexbox();
        @include align-items(center);
        label {
            width: 100%;
            font-size: $font-size-ml;
            line-height: 2.3rem;
        }
    }
    input {
        border-radius: 0.8rem;
        font-size: $font-size-ml;
        line-height: 2.3rem;
        background: $color-white;
        padding: 1.2rem;
        border: none;
        color: #8E9ABB;
        min-height: 4.8rem;
        &:focus{
            box-shadow: none;
        }
        @include md {
            font-size: 1.6rem;
        }
    }
    &.bold-type{
        input{
        font-weight: $font-bold !important;
        }
    }
}
