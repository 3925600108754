@import "../../css/breakpoints.scss";
@import "../../css/vars.scss";
@import "../../css/mixin.scss";
@import "../../css/sprite-positions.scss";

.notification-container {
    font-size: 14px;
    box-sizing: border-box;
    position: absolute;
    z-index: 999999;
    &.top-right {
        top: 12px;
        right: 12px;
        transition: transform 0.6s ease-in-out;
        animation: toast-in-right 0.7s;
    }

    &.bottom-right {
        bottom: 12px;
        right: 12px;
        transition: transform 0.6s ease-in-out;
        animation: toast-in-right 0.7s;
    }

    &.top-left {
        top: 12px;
        left: 12px;
        transition: transform 0.6s ease-in;
        animation: toast-in-left 0.7s;
    }

    &.bottom-left {
        bottom: 12px;
        left: 12px;
        transition: transform 0.6s ease-in;
        animation: toast-in-left 0.7s;
    }

    .notification-toast {
        background: #eff1f5;
        transition: 0.3s ease;
        position: relative;
        pointer-events: auto;
        overflow: hidden;
        margin: 0 0 6px;
        padding: 30px;
        margin-bottom: 15px;
        width: 300px;
        max-height: 100px;
        border-radius: 3px 3px 3px 3px;
        box-shadow: -2px 8px 15px #999;
        color: #000;
        opacity: 0.9;
        background-position: 15px;
        background-repeat: no-repeat;
        display: flex;
        &::before {
            content: "";
            background: url("../../img/notification-side-gb.png");
            position: absolute;
            top: 0;
            right: 0;
            width: 90%;
            height: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            @include md {
                width: 280px;
                z-index: -1;
            }
        }
        button {
            position: absolute;
            right: 12px;
            top: 12px;
            cursor: pointer;
            width: 24px;
            height: 24px;
            line-height: 1;
        }
    }

    .notification-title {
        font-weight: 700;
        font-size: 16px;
        text-align: left;
        margin-top: 0;
        margin-bottom: 6px;
        width: 300px;
    }

    .notification-message {
        margin: 0;
        text-align: left;
        margin-left: -1px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        position: relative;
        z-index: 1;
        font-weight: $font-bold;
        @include flexbox();
        @include align-items(center);
    }

    .notification-image {
        float: left;
        margin-right: 46px;
    }

    .notification-image img {
        width: 30px;
        height: 30px;
    }

    .toast {
        height: 50px;
        width: 365px;
        color: $color-white;
        padding: 14px 15px 10px 74px;
        @include md {
            width: 100%;
            padding: 1.4rem 4.5rem 1rem 12.2rem;
            width: 100%;
        }
    }

    .notification-container button {
        position: relative;
        right: -0.3em;
        top: -0.3em;
        float: right;
        font-weight: 700;
        color: #fff;
        outline: none;
        border: none;
        text-shadow: 0 1px 0 #fff;
        opacity: 0.8;
        line-height: 1;
        font-size: 16px;
        padding: 0;
        cursor: pointer;
        background: 0 0;
        border: 0;
    }
}

@keyframes toast-in-right {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes toast-in-left {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
}
