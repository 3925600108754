@import "../../css/mixin.scss";
@import "../../css/vars.scss";

.separator {
    border-bottom: .2rem solid $secondary-color;
    width: calc(100% - 30.4rem);
    margin: 4rem 0 4rem 15.2rem;
    &.dotted-line{
        border-bottom: .2rem dotted $secondary-color;
    }
}
