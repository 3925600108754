@import "../../css/breakpoints.scss";
@import "../../css/vars.scss";
@import "../../css/mixin.scss";
@import "../../css/sprite-positions.scss";

.notification {
    min-width: 51rem;
    background: $light-gray;
    border-radius: 2rem;
    padding: 3rem;
    position: fixed;
    right: 0;
    top: 2rem;
    height: calc(100vh - 40px);
    margin-right: 2rem;
    z-index: 9;
    @include xlg {
        right: -100%;
        transition: 0.5s;
        margin-right: 0;
        top: 0;
        border-radius: 0;
        min-width: 100%;
        &.open {
            right: 0;
            z-index: 999;
            @include md {
                padding: 1.6rem;
            }
        }
    }
    @include md {
        padding: 0;
    }
    &::before {
        content: "";
        background: url("../../img/notification-side-gb.png");
        position: absolute;
        top: 0;
        right: 0;
        width: 40rem;
        height: 45rem;
        background-repeat: no-repeat;
        background-size: cover;
        @include md {
            width: 280px;
            z-index: -1;
        }
    }
    .notification-title {
        font-size: 3rem;
        font-weight: $font-bold;
        margin-bottom: 3.2rem;
        padding-left: 7rem;
        @include md {
            font-size: $font-size-xl;
            font-weight: $font-bold;
            margin-bottom: 2.2rem;
            padding-left: 4rem;
        }
        position: relative;
        &::before {
            content: "";
            background: url("../../img/sprite3x.png");
            background-size: 1920px;
            background-position: -389px -404px;
            width: 32px;
            height: 32px;
            position: absolute;
            top: -6px;
            left: 0;
        }
    }
    .notification-inn-card {
        background: $color-white;
        padding: 2.4rem 1.6rem 10rem;
        border-radius: 2rem;
        position: relative;
        height: 100%;
        z-index: 1;
        .notification-inn-card-in {
            height: 100%;
            overflow-y: auto;
            overflow-x: hidden;
        }
        .notification-profile {
            @include flexbox();
            @include justify-content(space-between);
        }
        .ant-collapse {
            .ant-collapse-item {
                .ant-collapse-header {
                    height: 4rem;
                    font-size: $font-size-ml;
                    line-height: 2.2rem;
                    padding: 0.8rem 1.2rem;
                    box-shadow: 0px 1px 4px rgba(19, 22, 32, 0.08);
                    border-radius: 0.8rem;
                    background: transparent;
                }
                &.ant-collapse-item-active {
                    .ant-collapse-header {
                        background: transparent;
                        height: 4rem;
                        font-size: $font-size-ml;
                        line-height: 2.2rem;
                        padding: 0.8rem 1.2rem;
                        box-shadow: 0px 1px 4px rgba(19, 22, 32, 0.08);
                        border-radius: 0.8rem;
                        .ant-collapse-arrow {
                            top: calc(50% - 0.8rem);
                            @include xlg {
                                top: calc(50% - 1.2rem);
                            }
                        }
                    }
                }
            }
        }
        .call-icon {
            background: url("../../img/phone.png");
            width: 40px;
            height: 40px;
            position: absolute;
            bottom: 15px;
            right: 15px;
            background-repeat: no-repeat;
            cursor: pointer;
            @include xxl {
                width: 30px;
                height: 30px;
                background-size: contain;
                background-repeat: no-repeat;
            }
        }
    }
    .reminder-header {
        margin-top: 2.6rem;
        margin-bottom: 2.1rem;
        @include flexbox();
        @include justify-content(space-between);
        color: $heading-color;
        font-size: $font-size-ml;
        line-height: 2.3rem;
        padding-right: 2.8rem;
        .reminder-title {
            font-size: $font-size-xl;
            font-weight: $font-bold;
            line-height: 2.5rem;
        }
        .calender-icon {
            margin-left: 1.2rem;
            &:before {
                background-position: -258px -182px;
                width: 30px;
                height: 30px;
                position: absolute;
                top: -3px;
            }
            @include xxl {
                &:before {
                    background-position: -188px -131px;
                    width: 30px;
                    height: 30px;
                    position: absolute;
                    top: -5px;
                    right: -28px;
                    background-size: 1400px;
                }
            }
            @include md {
                &:before {
                    top: -1px;
                }
            }
        }
    }
    div#notification-custom-scroll {
        height: 70vh;
        overflow-y: auto;
    }

    div#notification-custom-scroll::-webkit-scrollbar {
        width: 6px;
    }

    div#notification-custom-scroll::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px #EFF1F5;
        border-radius: 10px;
    }

    div#notification-custom-scroll::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px #dce5f5;
    }
}

.notification-link {
    background: $light-gray;
    border-radius: $border-radius-base + 0.1rem;
    width: 3.6rem;
    height: 3.6rem;
    position: relative;
    cursor: pointer;
    top: 5px;
    right: 5px;
    @include xlg {
        display: none;
    }
    @include md {
        width: 3.2rem;
        height: 3.2rem;
    }
    &:before {
        content: "";
        background: url("../../img/sprite3x.png");
        background-size: 1920px;
        background-position: $notification;
        top: 6px;
        right: 6px;
        position: absolute;
        width: 24px;
        height: 24px;
    }
    @include xxl {
        &:before {
            content: "";
            background: url("../../img/sprite3x.png");
            background-size: 1200px;
            background-position: -119px -116px;
            width: 16px;
            height: 16px;
            top: 4px;
            left: 3px;
        }
    }
    @include xlg {
        &:before {
            top: 3px;
            left: 1px;
        }
    }
    @include md {
        &:before {
            top: 9px;
            left: 7px;
        }
    }
    .badge {
        background: linear-gradient(0deg, #c59434, #c59434);
        width: 1.4rem;
        height: 1.4rem;
        border-radius: 50%;
        position: absolute;
        top: -0.7rem;
        right: -0.7rem;
        @include md {
            width: 1rem;
            height: 1rem;
            top: -0.3rem;
            right: -0.3rem;
        }
    }
}
