@import "../../css/mixin.scss";
@import "../../css/vars.scss";
@import "../../css/breakpoints.scss";

.selectbox-wrap {
    .ant-select {
        width: 100% !important;
        margin-bottom: 8px;
        &:not(.ant-select-customize-input) {
            .ant-select-selector {
                border-radius: $gap-10;
                border: none;
                color: $light-blue;
                font-size: $font-size-ml;
                font-weight: $font-medium;
                background: $color-white;
                line-height: 4.8rem;
                min-height: 4.8rem;
            }
        }
        &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
            .ant-select-selector {
            box-shadow: none;
            border-color: $heading-color;
        }
        .ant-select-arrow {
            background: url("../../img/sprite3x.png");
            background-size: 1920px;
            background-position: -254px -104px;
            top: calc(50% - 1.7rem);
            position: absolute;
            width: 24px;
            height: 24px;
            svg {
                display: none;
            }
        }
    }
    .ant-select-single .ant-select-selector .ant-select-selection-item,
    .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
        line-height: 4.8rem;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        padding: 0 1.2rem;
    }
}

.ant-select-dropdown {
    width: 200px !important;
}

.ant-select-dropdown {
    width: 200px !important;
}
