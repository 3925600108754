@import "../../css/mixin.scss";
@import "../../css/vars.scss";
@import "../../css/sprite-positions.scss";
@import "../../css/breakpoints.scss";

.plan-card {
    background: rgba(255, 255, 255, 0.5);
    border: 2px solid $light-gray;
    box-sizing: border-box;
    box-shadow: 10px 10px 30px rgba(223, 226, 236, 0.4);
    border-radius: $border-radius-base + 0.8rem;
    padding: 1.1rem;
    width: 33.333%;
    text-align: center;
    padding: 60px 0 60px;
    position: relative;
    @include flexbox();
    @include align-items(center);
    @include flex-direction(column);
    @include md {
        width: 100%;
    }
    &.active {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6));
        border: 0.3rem solid $gray-border;
        box-shadow: 10px 10px 30px rgba(223, 226, 236, 0.4);
        border-radius: $border-radius-base + 0.8rem;
        .edit {
            position: absolute;
            right: 20px;
            top: 20px;
            display: inline-block;
            width: 30px;
            height: 32px;
            cursor: pointer;
            &::before {
                content: "";
                background-position: $check;
                width: 30px;
                height: 32px;
                position: absolute;
                right: 0;
            }
        }
    }
    h1 {
        margin-bottom: $gap-16;
    }
    h2 {
        margin-bottom: $gap-08;
    }
    .valid {
        font-size: $font-size-xl;
        max-width: 19.2rem;
        margin: 0 auto;
    }
    .watermark-value {
        font-size: 6.4rem;
        line-height: 6.4rem;
        font-weight: $font-bold;
        color: rgba(146, 146, 116, 0.2);
        position: absolute;
        bottom: 1rem;
        left: 1.3rem;
    }
    .edit {
        position: absolute;
        right: 20px;
        top: 20px;
        display: inline-block;
        width: 30px;
        height: 32px;
        cursor: pointer;
        &::before {
            content: "";
            background-position: $edit;
            width: 30px;
            height: 32px;
            position: absolute;
            right: 0;
        }
    }
}
