@import "../../css/breakpoints.scss";

.carousel-img {
    width: 28.8rem;
    height: 17rem;
    @include md {
        width: auto;
        height: auto;
    }
    img {
        max-width: 100%;
        margin: 0 auto;
    }
    .ant-carousel {
        .slick-prev {
            left: 0;
        }
        .slick-dots {
            &.slick-dots-bottom {
                bottom: -40px;
            }
            li {
                background: rgba(93, 110, 158, 0.2);
                min-height: 12px;
                padding: 0;
                border-radius: 50%;
                max-width: 12px;
                &.slick-active {
                    background: #5d6e9e;
                    max-width: 30px;
                    min-width: 30px;
                    border-radius: 12px;
                    button {
                        background: #5d6e9e;
                        opacity: 0;
                    }
                }
            }
        }
    }
    .ant-carousel {
        .slick-next {
            right: 10px;
            background: url("../../img/sprite3x.png");
            background-size: 1920px;
            background-position: -394px -260px;
            position: absolute;
            width: 24px;
            height: 24px;
            z-index: 9;
            transform: rotateY(180deg);
            -webkit-transform: rotateY(180deg);
        }
        .slick-prev {
            right: auto;
            left: 10px;
            background: url("../../img/sprite3x.png");
            background-size: 1920px;
            background-position: -394px -260px;
            position: absolute;
            width: 24px;
            height: 24px;
            z-index: 9;
        }
    }
}
