@import "../../css/vars.scss";
@import "../../css/mixin.scss";
@import "../../css/sprite-positions.scss";
@import "../../css/breakpoints.scss";

.header {
    box-shadow: $box-shadow-base;
    background: $color-white;
    height: $header-height;
    @include flexbox();
    @include justify-content(space-between);
    padding-right: 55.7rem;
    padding-left: 14.0rem;
    position: fixed;
    width: 100%;
    @include align-items(center);  
    @include xlg {
        padding-right: $gap-16;
        width: 100%;
        padding-left: $gap-16;
        z-index: 99;
        h1{
            @include flexbox();
            @include align-items(center);
            span{
                padding-left: 8px;
            }
        }
    }  
    @include md {
        height: 4.4rem;
        h1{
            font-size: 2rem;
        }
    }
    z-index: 1;
    .header-title{
        font-size: 3.5rem;
        font-weight: $font-bold;
        @include xlg {
            font-size: 3rem;
            @include flexbox();
            @include align-items(center);
            img{
                margin-right: 0.8rem;
            }
        }
        @include md {
            font-size: 2rem;
            @include flexbox();
            @include align-items(center);
            img{
                margin-right: 0.8rem;
            }
        }
    }
    .header-time {
        font-size: $font-size-1xl;
        color: $heading-color;
        font-weight: $font-semi-bold;
        position: relative;
        padding-right: 3.8rem;
        @include xlg {
            display: none;
        }
        &::before {
            content: "";
            background: url("../../img/sprite3x.png");
            background-size: 1920px;
            background-position: $time;
            top: -2px;
            right: 0;
            position: absolute;
            width: 28px;
            height: 28px;            
        }
        @include xxl {
            &::before {
                content: "";
                background: url("../../img/sprite3x.png");
                background-size: 1200px;
                background-position: -71px -67px;
                top: -2px;
                width: 20px;
                height: 20px;            
            }
        }
    }
    .notification-icon{
        min-width: 3rem;
        min-height: 3rem;
        border-radius: 0.5rem;
        img{
            max-width: 100%;
        }
    }
}
