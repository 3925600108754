@import './vars';

html body {
  line-height: 1;
}

/*--------------------------------------------------------------
# ant design aside override css
--------------------------------------------------------------*/

.ant-layout{
  background: transparent;
}
.ant-layout-sider {
  background: $primary-color;
}
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background: transparent;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected,
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background: rgba(0, 0, 0, 0.2);
}
