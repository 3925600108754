/*--------------------------------------------------------------
# Basic colors
--------------------------------------------------------------*/

$primary-color: #2F374F; // primary color for all components

$secondary-color:#5D6E9E;  // secondary color for all components

$secondary-color-dark: #4A87F5;

$light-gray: #EFF1F5;

$dark-gray: #f0f2f6;

$gay-text: #BEC5D8;

$light-sky: #dce5f5;

$light-sky-2 :#D3DFF5;

$sky-color: #F2F7FF;

$light-blue: #8E9ABB;

$gray-border: #929274;

$link-color: #1890ff; // link color

$success-color: #32c788; // success state color

$warning-color: #faad14; // warning state color

$error-color: #f5222d; // error state color

$heading-color: #131620; // heading text color

$text-color: rgba(0, 0, 0, 0.65); // major text color

$text-color-secondary: rgba(0, 0, 0, 0.45); // secondary text color

$disabled-color: rgba(0, 0, 0, 0.25); // disable state color

$border-color-base: rgba(165, 188, 223, 0.7); // major border color

$input-hover-border-color: rgba(165, 188, 223, 0.7);

$input-border-color: rgba(165, 188, 223, 0.7);

$color-white: #ffffff;

$color-warning: #ED5B75;

/*--------------------------------------------------------------
# Defined Basic Font Weight
--------------------------------------------------------------*/

$font-thin: 100;
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semi-bold: 600;
$font-bold: 700;
$font-ex-bold: 900;

/*--------------------------------------------------------------
# Defined Basic border radius
--------------------------------------------------------------*/

$border-radius-base: 0.4rem; // major border radius

/*--------------------------------------------------------------
# Defined Basic box shadow
--------------------------------------------------------------*/

$box-shadow-base: 0px 4px 40px rgba(0, 0, 0, 0.06); // major shadow for layers

$box-shadow-base-2: 0px 1px 4px rgba(19, 22, 32, 0.08);

/*--------------------------------------------------------------
# Defined Basic components Dimension
--------------------------------------------------------------*/

$header-height: 6.0rem;
$sidebar-width: 22.0rem;
$menu-width: 20.0rem;
$menu-compact-width: 5.0rem;
$component-inner-spaceing: 0.3rem;
$page-horizontal-space: 4.0rem;
$sidebar-helper-width: $page-horizontal-space - 5.0rem;

/*--------------------------------------------------------------
# Defined Basic fonts
--------------------------------------------------------------*/
$font-size-base: 1.4rem; // major text font size
$font-size-xxs: 0.9rem;
$font-size-xxm: 1rem;
$font-size-xs: 1.1rem;
$font-size-xsm: 1.2rem;
$font-size-s: 1.3rem;
$font-size-l: 1.6rem;
$font-size-ml: 1.8rem;
$font-size-xl: 2rem; 
$font-size-lxl: 2.1px;
$font-size-xxl: 2.2rem;
$font-size-1xl: 2.4rem;
$font-size-heading: 2.4rem;
$font-size-2xl: 3.0rem;
$font-size-xxxl: 3.6rem;
$font-size-g: 4.8rem;

/*--------------------------------------------------------------
# Defined Gap variable
--------------------------------------------------------------*/

$gap-01: 0.1rem;
$gap-02: 0.2rem;
$gap-03: 0.3rem;
$gap-04: 0.4rem;
$gap-05: 0.5rem;
$gap-06: 0.6rem;
$gap-07: 0.7rem;
$gap-08: 0.8rem;
$gap-09: 0.9rem;
$gap-10: 1.0rem;
$gap-11: 1.1rem;
$gap-12: 1.2rem;
$gap-13: 1.3rem;
$gap-14: 1.4rem;
$gap-15: 1.5rem;
$gap-16: 1.6rem;


/*--------------------------------------------------------------
# Defined Screen sizes
--------------------------------------------------------------*/

$lg-desktop:1840px;
$medium-desktop:1600px;
$max-desktop:1440px;
$desktop: 1201px;
$min-desktop: 1200px;
$lg-tablet: 1024px;
$tablet: 992px;
$min-tablet: 991px;
$tablet-medium: 767px;
$mobile: 479px;

/*--------------------------------------------------------------
# Header size
--------------------------------------------------------------*/

$header-height: 9.0rem