@import "../../css/vars.scss";
@import "../../css/mixin.scss";
@import "../../css/breakpoints.scss";

.step-slide {
    @include flexbox();
    .step-dot {
        @include flex(1);
        min-width: 36%;
        position: relative;
        &:last-child {
            &::before {
                display: none;
            }
        }
        &::before {
            content: "";
            background: $gay-text;
            width: calc(100% - 40px);
            height: 2px;
            position: absolute;
            left: 35px;
            top: 14px;
        }
        &.active {
            span{
                background: $secondary-color-dark;
            }
            &:last-child {
                &::after {
                    display: none;
                }
            }
            &::after {
                content: "";
                background: $secondary-color-dark;
                width: calc(50% - 32px);
                height: 2px;
                position: absolute;
                left: 36px;
                top: 14px;
            }
        }
        span {
            width: 30px;
            height: 30px;
            background: $light-blue;
            display: block;
            border-radius: 50%;
            font-size: 16px;
            @include flexbox();
            @include justify-content(center);
            @include align-items(center);
            font-weight: $font-medium;
            color: $color-white;
        }
        .status {
            display: inline-block;
            height: 23px;
            border: 1px solid $light-blue;
            font-size: 10px;
            font-weight: $font-bold;
            border-radius: 4px;
            padding: 4px;
            display: inline-flex;
            @include align-items(center);
            text-transform: capitalize;
            &.progress{
                background: rgba(211, 223, 245, 0.6);
                color: $secondary-color-dark;
                border: none;
            }
            &.Completed{
                background: rgba(146, 146, 116, 0.2);
                color: $gray-border;
                border: none;
            }
        }
        .titles{
            color: $heading-color;
            font-size: 12px;
            font-weight: $font-semi-bold;
            margin-top: 14px;
            line-height: 18px;
            margin-bottom: 8px;
        }
    }
}
.steps-action{
    margin-top: 40px;
    button {
        margin-right: 1.6rem;
        min-width: calc(50% - 0.8rem) !important;
        & + button {
            margin-right: 0;
        }
    }
}
