@import "../../css/mixin.scss";
@import "../../css/vars.scss";
@import "../../css/breakpoints.scss";

.book-appointment-location-wrap {
    background: $color-white;
    border: 1px solid $gray-border;
    box-shadow: 0px 1px 4px rgba(19, 22, 32, 0.08);
    border-radius: $gap-08;
    padding: 2.4rem;
    position: relative;
    @include md {
        padding: 6.5rem 0.8rem 2.4rem 0.8rem;
    }
    .user-profile-wrap {
        @include flexbox();
        @include align-items(flex-start);
        .img-area {
            min-width: 7rem;
            min-height: 7rem;
            border-radius: $border-radius-base;
            overflow: hidden;
            margin-right: $gap-16;
            @include md {
                min-width: 6rem;
                min-height: 6rem;
            }
            img {
                max-width: 100%;
            }
        }
        .user-details {
            color: $heading-color;
            width: 100%;
            @include md {
                margin-left: -7.4rem;
            }
            .name {
                font-size: $font-size-heading + 0.4rem;
                font-weight: $font-bold;
                line-height: 3.5rem;
                margin-bottom: 0.4rem;
                @include md {
                    font-size: 2rem;
                    line-height: 2.5rem;
                    margin-left: 7.4rem;
                }
            }
            .details {
                font-weight: $font-medium;
                font-size: $font-size-heading;
                line-height: 3rem;
                margin-bottom: $gap-16;
                @include md {
                    font-size: 2rem;
                    margin-left: 7.4rem;
                }
            }
            .separator {
                width: calc(100% - 14.4rem);
                margin: 4rem 0 4rem 1.2rem;
                @include md {
                    width: 100%;
                    margin: 4rem 0 4rem 0rem;
                }
            }
            .availability-calendar {
                @include flexbox();
                margin-bottom: 4rem;
                @include md {
                    @include flex-wrap(wrap);
                    margin-bottom: 0;
                    .view-slot-calendar {
                        font-weight: $font-medium;
                        font-size: $font-size-xl;
                        text-decoration: underline;
                        text-align: center;
                        width: 100%;
                        margin: 4rem 0;
                    }
                }
                .calendar-area {
                    background: linear-gradient(128deg, #ffffff -79.65%, #ffffff 151.25%);
                    box-shadow: -7px -7px 16px #fafbfc, 4px 3px 19px #eff1f5;
                    border-radius: $gap-10;
                    max-width: 30rem;
                    @include md {
                        max-width: 28rem;
                    }
                    .react-calendar {
                        font-size: 1.4rem;
                        border: none;
                        .react-calendar__tile--active {
                            background: #008000;
                        }
                        .react-calendar__tile--now {
                            background: $gray-border;
                        }
                        button {
                            max-width: 3rem;
                            max-height: 3rem;
                            padding: 0;
                        }
                    }
                    .react-calendar__month-view__days__day--neighboringMonth {
                        background: #008000 !important;
                        color: $color-white;
                        border-bottom: 1px solid $color-white;
                        border-right: 1px solid $color-white;
                    }
                    .react-calendar__tile:enabled:hover,
                    .react-calendar__tile:enabled:focus {
                        background: $gray-border;
                        color: $color-white;
                    }
                    .react-calendar__month-view__days__day--weekend {
                        background: #ff6766 !important;
                        color: $color-white;
                        border-bottom: 1px solid $color-white;
                        border-right: 1px solid $color-white;
                    }
                }
                .time-slot-area {
                    background: linear-gradient(128deg, #ffffff -79.65%, #ffffff 151.25%);
                    box-shadow: -7px -7px 16px #fafbfc, 4px 3px 19px #eff1f5;
                    border-radius: $gap-10;
                    margin-left: 4rem;
                    padding: 1.8rem 2.8rem;
                    width: 100%;
                    margin-right: 13.2rem;
                    @include md {
                        margin-left: 0;
                        margin-right: 0;
                        padding: 1.8rem 0.8rem;
                    }
                    h2 {
                        margin-bottom: 2.7rem;
                    }
                    table {
                        tbody {
                            tr {
                                &:nth-child(3) {
                                    td {
                                        &:nth-child(2) {
                                            background: $gray-border;
                                            color: $color-white;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .initial-hide-on-mob {
                    @include md {
                        display: none;
                        &.view-on-mobile {
                            @include md {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
    .card-footer {
        margin-top: 0.8rem;
        @include flexbox();
        @include justify-content(space-between);
        @include align-items(center);
        background: rgba(239, 241, 245, 0.3);
        box-shadow: $box-shadow-base-2;
        border-radius: $gap-08;
        width: calc(100% + 4.8rem);
        margin-left: -2.4rem;
        margin-bottom: -2.4rem;
        min-height: 8rem;
        height: auto;
        padding: 0.8rem 2.4rem 0.8rem 2.7rem;
        @include flex-wrap(wrap);
        @include md {
            height: auto;
            width: calc(100% + 1.6rem);
            margin-left: -0.8rem;
            padding: 2.4rem 0.8rem;
            &.btn-wrap {
                button {
                    min-width: 27.6rem;
                    margin-left: 0;
                }
            }
        }
        .location-tags {
            @include flexbox();
            @include flex-wrap(wrap);
            .tags {
                margin: 0.8rem 0.8rem 0.8rem 0;
            }
            & + button {
                @include md {
                    margin-top: 52px;
                }
            }
        }
        &.mt-20 {
            margin-top: 2rem;
        }
        &.btn-wrap {
            @include justify-content(flex-end);
            height: auto;
            @include md {
                @include justify-content(center);
            }
            button {
                margin-left: 1.6rem;
                @include md {
                    margin-bottom: 1.6rem;
                }
                @include md {
                    margin-left: 0;
                }
                & + button {
                    margin-bottom: 0;
                }
            }
        }
        button {
            @include md {
                width: calc(100% - 32px);
                margin-left: 16px;               
            }
        }
    }
    .checkbox-wrap {
        position: absolute;
        top: 1.9rem;
        right: 1.9rem;
        .active-check {
            background: url("../../img/checkbox-circle.png");
            background-size: cover;
            width: 2.5rem;
            height: 2.5rem;
            position: relative;
        }
    }
    &.active {
        .checkbox-wrap {
            .active-check {
                background: url("../../img/checkbox-circle-active.png");
                background-size: cover;
                width: 2.5rem;
                height: 2.5rem;
            }
        }
    }
    .tags {
        background: $dark-gray;
        border: 0.5px solid $light-sky-2;
        border-radius: $border-radius-base;
        padding: 1.1rem 1.6rem 1.1rem 4.8rem;
        font-size: $font-size-xl;
        line-height: 1.8rem;
        margin-right: 0.8rem;
        cursor: pointer;
        display: inline-block;
        position: relative;
        @include md {
            padding: 1.1rem 1.6rem 1.1rem 3.2rem;
        }
        &::before {
            content: "";
            background: url("../../img/Location.png");
            position: absolute;
            width: 24px;
            height: 24px;
            left: 1.6rem;
            top: calc(50% - 12px);
            background-size: cover;
        }
        @include xxl {
            &::before {
                width: 16px;
                height: 16px;
                left: 0.8rem;
                top: calc(50% - 8px);
            }
        }
        @include md {
            margin-bottom: 2.4rem;
            display: block;
            &::before {
                width: 20px;
                height: 20px;
                left: 0.8rem;
                top: calc(50% - 10px);
            }
        }
        &.active {
            background: $gray-border;
            border: 0.5px solid $gray-border;
            color: $color-white;
            position: relative;
            &::before {
                content: "";
                background: url("../../img/Location-white.png");
                position: absolute;
                width: 24px;
                height: 24px;
                left: 1.6rem;
                top: calc(50% - 12px);
                background-size: cover;
            }
            @include xxl {
                &::before {
                    width: 16px;
                    height: 16px;
                    left: 0.8rem;
                    top: calc(50% - 8px);
                }
            }
            @include md {
                &::before {
                    width: 20px;
                    height: 20px;
                    left: 0.8rem;
                    top: calc(50% - 10px);
                }
            }
        }
        &.green-active {
            background: linear-gradient(309.43deg, #131620 -142.29%, #5d6e9e 78.58%);
            border: 0.5px solid $gray-border;
            color: $color-white;
        }
    }
    .indications {
        font-size: $font-size-base;
        margin-top: 1.6rem;
        div {
            padding-left: 1.6rem;
            position: relative;
            line-height: 1.8rem;
            margin-bottom: 0.8rem;
            .red {
                background: #ff6766;
                width: 1.2rem;
                height: 1.2rem;
                display: inline-block;
                position: absolute;
                top: 0.3rem;
                left: 0;
            }
            .green {
                background: #008000;
                @extend .red;
            }
            .gray {
                background: $gray-border;
                @extend .red;
            }
        }
    }
}
