@import "../../css/vars";
@import "../../css/mixin";
@import "../../css/breakpoints.scss";

.ant-modal {
    .ant-modal-content {
        border-radius: 1.2rem;
        @include md {
            border-radius: 2rem;
        }
        .ant-modal-close-x {
            width: 25px;
            height: 25px;
            margin-top: 26px;
            margin-right: 26px;
            background: url('../../img/CloseSquare.png');
            svg{
                display: none;
            }
        }
    }
}
