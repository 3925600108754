@import "./vars";
@import "./mixin";
@import "./breakpoints.scss";

:root {
  font-size: 10px; // 1rem
  @include xxl {
    font-size: 7px;
  }
  @include xl {
    font-size: 6px;
  }
  @include md {
    font-size: 10px;
  }
}

.icon {
  width: 50px;
  height: 50px;
  margin: 8px;
}

.flex {
  @include flexbox();
}
.justify-content-sp-bt {
  @include justify-content(space-between);
}
.justify-content-sp-ev {
  @include justify-content(space-evenly);
}
.justify-content-flex-end {
  @include justify-content(flex-end);
}
.justify-content-center {
  @include justify-content(center);
}
.justify-content-flex-start {
  @include justify-content(flex-start);
}
.align-center {
  @include align-items(center);
}
.align-baseline {
  @include align-items(baseline);
}
.align-flex-start {
  @include align-items(flex-start);
}
.flex-dir-column {
  @include flex-direction(column);
}

html {
  body {
    font-size: $font-size-xl;
    font-family: "Mulish", sans-serif;
    background: $color-white;
    font-size: $font-size-1xl !important;
    font-weight: $font-regular;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 0;
}

h1 {
  font-size: $font-size-xxxl;
  font-weight: $font-bold;
  @include md {
    font-size: $font-size-1xl;
  }
}
h2 {
  font-size: $font-size-heading;
  line-height: 3.5rem;
  font-weight: $font-bold;
  @include md {
    font-size: $font-size-xl;
    line-height: 2.5rem;
  }
}
.bold {
  font-weight: $font-bold;
}
// common class

.title-green {
  color: $success-color;
}
.font-normal {
  font-weight: $font-regular;
}
.text-center {
  text-align: center;
}

// sprite image code

.sprite-img {
  background: url("../img/sprite3x.png");
  background-size: 1920px;
}
.sprite-img-before {
  position: relative;
  &:before {
    content: "";
    background: url("../img/sprite3x.png");
    background-size: 1920px;
  }
}

.title-underline {
  position: relative;
  &::before {
    content: "";
    width: 32px;
    height: 3px;
    background: #32c788;
    position: absolute;
    left: 0;
    top: 29px;
  }
}

#root {
  height: 100%;
  .app {
    height: 100%;
    .screen-wrapper {
      height: 100%;
      @include flexbox;
      .screen-content-wrapper {
        @include flexbox;
        width: 100%;
      }
    }
  }
}

.form-group-shadow {
  box-shadow: none;
  padding: 10px 12px 4px;
  background: rgba(210, 221, 239, 0.2);
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.04);
}

.unavailable-wrap {
  height: 66vh;
  @include flexbox();
  @include flex-direction(column);
  @include align-items(center);
  @include justify-content(center);
  .unavailable-text {
    font-weight: $font-bold;
    font-size: $font-size-heading;
    color: $heading-color;
    margin-top: 2.4rem;
  }
}

.unavailable-wrap {
  height: 66vh;
  @include flexbox();
  @include flex-direction(column);
  @include align-items(center);
  @include justify-content(center);
  .unavailable-text{
    font-weight: $font-bold;
    font-size: $font-size-heading;
    color: $heading-color;
    margin-top: 2.4rem;
  }
}


/*--------------------------------------------------------------
# Override code
--------------------------------------------------------------*/

.ant-collapse {
  font-size: $font-size-1xl;
  line-height: 3.6rem;
  letter-spacing: 0.1rem;
  @include md {
    font-size: $font-size-l;
  }
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 1.6rem 0 2.4rem 0;
  @include md {
    padding: 1.6rem 0;
  }
}

/*--------------------------------------------------------------
# view payment plan
--------------------------------------------------------------*/

.view-payment-plan{
  .ant-modal-body{
    padding: 4rem;
    @include md {
      padding: 4rem 1.6rem;
    }
  }
  h1{
    font-weight: $font-bold;
    margin-bottom: 2.4rem;
  }
  p{
    margin-bottom: 0.8rem;
  }
  .plan-details{
    font-size: $font-size-ml;
    margin-bottom: 3.2rem;
    .value{
      font-weight: $font-semi-bold;
    }
    .key{
      min-width: 20rem;
      display: inline-block;
    }
  }
}

/*--------------------------------------------------------------
# margin variables
--------------------------------------------------------------*/
.mt-16 {
  margin-top: $gap-16;
}
.mb-16 {
  margin-bottom: 1.6rem;
}
.mb-24 {
  margin-bottom: 2.4rem;
}
.mt-24 {
  margin-top: 2.4rem;
}
.mt-20 {
  margin-top: 20px;
}

.mobile-none {
  @include md {
    display: none !important;
  }
}
.desktop-none {
  @include xl {
    display: block !important;
  }
}
.tab-only {
  display: none;
  @include xlg {
    display: block;
  }
}
