@import "../../css/vars.scss";
@import "../../css/mixin.scss";
@import "../../css/breakpoints.scss";

.appointment-card {
    background: rgba(223, 226, 236, 0.2);
    border: 0.770559px solid rgba(223, 226, 236, 0.1);
    border-radius: $border-radius-base + 0.8rem;
    border: 0.770559px solid #f9f9fb;
    padding: $gap-16;
    color: $heading-color;
    position: relative;
    .user-profile-wrap {
        @include flexbox();
        .img-area {
            margin-right: $gap-16;
            width: 6rem;
            height: 6rem;
            overflow: hidden;
            border-radius: $border-radius-base;
            img {
                max-width: 100%;
            }
        }
        .user-details {
            line-height: 2.5rem;
            .name {
                font-size: $font-size-xl;
                font-weight: $font-bold;
            }
            .details {
                font-size: $font-size-xl;
                font-weight: $font-regular;
            }
        }
    }
    .date-time {
        @include flexbox();
        margin-top: 1.8rem;
        .date {
            font-size: $font-size-l;
            font-weight: $font-semi-bold;
            padding-left: 3rem;
            position: relative;
            @include xlg {
                padding-left: 4rem;
            }
            &:before {
                content: "";
                background-position: -327px -187px;
                position: absolute;
                width: 20px;
                height: 21px;
                left: 0;
                top: 0.7rem;
            }
            @include xxl {
                padding-left: 3.6rem;
                &:before {
                    content: "";
                    background-position: -237px -135px;
                    background-size: 1400px;
                    position: absolute;
                    width: 20px;
                    height: 21px;
                    left: 0;
                    top: 0.5rem;
                }
            }
            @include xlg {
                &:before {
                    top: 0.3rem;
                }
            }
        }
        .time {
            @extend .date;
            &:before {
                content: "";
                background-position: -260px -327px;
                position: absolute;
                width: 20px;
                height: 22px;
                left: 0;
                top: 0.7rem;
            }
            @include xxl {
                &:before {
                    content: "";
                    background-position: -189px -238px;
                    background-size: 1400px;
                    position: absolute;
                    width: 20px;
                    height: 21px;
                    left: 0;
                    top: 0.5rem;
                }
            }
        }
        .mid-at {
            @extend .date;
            margin: 0 18px 0 16px;
            padding-left: 0;
            &::before {
                display: none;
            }
        }
    }
    &.active {
        background: $secondary-color;
        color: $color-white;
    }
    .appointment-arrow {
        width: 29px;
        height: 29px;
        position: absolute;
        right: 1.8rem;
        top: 1.8rem;
        &:before {
            background-position: -321px -257px;
            position: absolute;
            width: 32px;
            height: 32px;
        }
    }
}
