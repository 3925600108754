@import "../../css/vars.scss";
@import "../../css/mixin.scss";
@import "../../css/breakpoints.scss";

.book-appointment-wrap {
    background: $color-white;
    border-radius: $gap-08;
    box-shadow: $box-shadow-base-2;
    padding: 2.4rem;
    .appointment-profile-wrap {
        @include flexbox();
        @include justify-content(space-between);
        @include align-items(center);
        @include flex-wrap(wrap);
        .appointment-profile-wrap-in {
            @include flexbox();
            @include align-items(center);
            @include align-items(flex-start);
            .img-area {
                width: 7rem;
                height: 7rem;
                border-radius: $border-radius-base;
                overflow: hidden;
                margin-right: $gap-16;
                img {
                    max-width: 100%;
                }
            }
            .appointment-user-details {
                color: $heading-color;
                .name {
                    font-size: $font-size-heading + 0.4rem;
                    font-weight: $font-bold;
                    line-height: 3.5rem;
                    margin-bottom: 0.4rem;
                    @include md {
                        font-size: 2rem;
                        line-height: 2.5rem;
                    }
                }
                .details {
                    font-weight: $font-medium;
                    font-size: $font-size-heading;
                    line-height: 3rem;
                    @include md {
                        font-size: 2rem;
                    }
                }
            }
        }
        .book-appointment {
            @include flexbox();
            @include flex-direction(column);
            margin-left: 7.2rem;
            margin-top: 1.6rem;
            button {
                margin-bottom: 1.6rem;
                & + button {
                    margin-bottom: 0;
                }
            }
        }
    }
    .appointment-bottom {
        margin-top: 2.4rem;
        @include flexbox();
        @include justify-content(space-between);
        @include align-items(center);
        background: rgba(239, 241, 245, 0.3);
        box-shadow: $box-shadow-base-2;
        border-radius: $gap-08;
        width: calc(100% + 4.8rem);
        margin-left: -2.4rem;
        margin-bottom: -2.4rem;
        min-height: 8rem;
        height: auto;
        padding: 1.6rem 2.4rem 0rem 2.7rem;
        @include md {
            height: auto;
            flex-wrap: wrap;
            padding: 1.6rem 2rem 1.6rem 2rem;
        }
        .appointment-bottom-button {
            margin-bottom: 1.6rem;
            @include md {
                width: 100%;
            }
            button {
                width: 20rem;
                @include md {
                    width: calc(100% - 64px);
                    height: 4.8rem;
                    min-height: 4.8rem;
                    margin-top: 2rem;
                    margin-left: 32px;
                }
            }
        }
        .appointment-bottom-details {
            @include flexbox();
            color: $heading-color;
            font-size: $font-size-heading;
            line-height: 1.8rem;
            @include flex-wrap(wrap);

            @include md {
                font-size: 1.6rem;
            }
            div {
                margin-right: 4.4rem;
                padding-left: 3.5rem;
                margin-bottom: 1.6rem;
                position: relative;
                @media (max-width:1320px) {
                    min-width: 180px;
                    margin-bottom: 2.2rem;
                }
                @include xlg{
                    padding-left: 4rem;
                    margin-bottom: 2rem;
                }
                @include md{
                    padding-left: 2.5rem;
                }
                &.date {
                    &:before {
                        content: "";
                        background: url("../../img/Calendar.png");
                        background-repeat: no-repeat;
                        width: 24px;
                        height: 28px;
                        position: absolute;
                        left: 0;
                        top: -4px;
                    }
                    @include xxl {
                        &:before {
                            width: 16px;
                            height: 18px;
                            background-size: cover;
                            position: absolute;
                            
                        }
                    }
                    @include md {
                        &:before {
                            top: 0px;
                            
                        }
                    }
                }
                &.time {
                    &:before {
                        content: "";
                        background: url("../../img/TimeCircle.png");
                        background-repeat: no-repeat;
                        width: 26px;
                        height: 28px;
                        position: absolute;
                        left: 0;
                        top: -4px;
                    }
                    @include xxl {
                        &:before {
                            width: 17px;
                            height: 17px;
                            background-size: cover;
                            position: absolute;
                            top: -3px;
                            
                        }
                    }
                    @include md {
                        &:before {
                            top: 0px;
                            
                        }
                    }
                }
                &.location {
                    &:before {
                        content: "";
                        background: url("../../img/Location.png");
                        background-repeat: no-repeat;
                        background-size: cover;
                        width: 30px;
                        height: 30px;
                        position: absolute;
                        left: 0;
                        top: -4px;
                    }
                    @include xxl {
                        &:before {
                            width: 20px;
                            height: 20px;
                            background-size: cover;
                            position: absolute;
                            top: -4px;
                            
                        }
                    }
                    @include md {
                        &:before {
                            top: 0px;
                            left: -3px;
                        }
                    }
                }
                &.self {
                    &:before {
                        content: "";
                        background: url("../../img/self.png");
                        background-repeat: no-repeat;
                        width: 26px;
                        height: 28px;
                        position: absolute;
                        left: 0;
                        top: -4px;
                    }
                    @include xxl {
                        &:before {
                            width: 17px;
                            height: 17px;
                            background-size: cover;
                            position: absolute;
                            top: -3px;
                            
                        }
                    }
                    @include md {
                        &:before {
                            top: 0px;                           
                            
                        }
                    }
                }
                @include md {
                    margin-right: 4rem;
                    &.time,
                    &.self {
                        margin-right: 0;
                    }
                }
            }
        }
    }
    & + .separator {
        @include md {
            width: calc(100% - 4rem);
            border-bottom: 0.2rem dotted #5d6e9e;
            margin: 4rem auto 4rem auto;
            & + .appointment-title-head {
                p {
                    margin-top: 0;
                }
            }
        }
    }
}
