@import "../../css/vars.scss";
@import "../../css/mixin.scss";
@import "../../css/breakpoints.scss";
@import "../../css/sprite-positions.scss";

aside {
    .mobile-logo {
        padding: 2.5rem 1.2rem 6rem;
        text-align: center;
        position: relative;
        &:before {
            content: "";
            background: url("../../img/logo-line.png");
            width: calc(100% - 50px);
            height: 1px;
            position: absolute;
            top: 8.5rem;
            left: 25px;
        }
        img {
            max-width: 5rem;
        }
    }
    .logo {
        text-align: center;
        padding: 1.7rem 1.2rem 6rem;
        position: relative;
        img {
            max-width: 17.4rem;
        }
        &:before {
            content: "";
            background: url("../../img/logo-line.png");
            width: calc(100% - 50px);
            height: 1px;
            position: absolute;
            top: 8.5rem;
            left: 25px;
            @include md {
                top: auto;
                bottom: 0;
            }
        }
        @include lg {
            @include md {
                padding: 48px 12px 8px 40px;
                text-align: left;
            }
            img {
                max-width: 80px;
                width: 80px;
                @include md {
                    max-width: 180px;
                    width: 180px;
                }
            }
        }
    }

    .close-nav {
        background: url("../../img/CloseNav.png");
        text-indent: -99999px;
        width: 3.3rem;
        height: 3.3rem;
        position: absolute;
        top: 3rem;
        right: -1.6rem;
        border: none;
        padding: 0;
        background-size: cover;
        cursor: pointer;
        @include md {
            top: 6.3rem;
            right: -1.8rem;
        }
    }
    &.ant-layout-sider {
        position: fixed;
        z-index: 99;
        @include xlg {
            flex: 0 0 28rem !important;
            max-width: 28rem !important;
            min-width: 28rem !important;
            width: 28rem !important;
        }
        .mobile-logo {
            display: none;
        }
        &.ant-layout-sider-collapsed {
            @include xlg {
                flex: 0 0 0rem !important;
                max-width: 0rem !important;
                min-width: 0rem !important;
                width: 0rem !important;
            }
            .close-nav {
                transform: rotateY(-180deg);
                -webkit-transform: rotateY(-180deg);
                @include xlg {
                    display: none;
                }
            }
            .logo {
                display: none;
            }
            .mobile-logo {
                display: block;
                @include xlg {
                    display: none;
                }
            }
            li {
                .ant-menu-inline-collapsed-noicon {
                    display: none;
                }
            }
            .log-out {
                @include xlg {
                    display: none;
                }
                span {
                    display: none;
                }
            }
        }
        .ant-layout-sider-zero-width-trigger {
            color: #1f94cd;
            font-size: 20px;
            line-height: 32px;
            text-align: center;
            background: #fff;
            border-radius: 4px;
            top: 4px;
            height: 30px;
            width: 30px;
            right: -38px;
        }
    }
    ul {
        padding-left: 0;
        height: 58vh;
        overflow: auto;
        &.ant-menu {
            @include md {
                margin-top: 4rem;
                height: calc(100vh - 280px);
            }
        }

        li {
            list-style: none;
            padding: 0.8rem 0.8rem 0.8rem 5.6rem !important;
            cursor: pointer;
            position: relative;
            font-size: $font-size-xl;
            margin: 0 2.9rem !important;
            width: calc(100% - 5.8rem) !important;
            height: 60px;
            outline: none;
            @include xxl {
                padding: 0.8rem 0.8rem 0.8rem 6.4rem !important;
                margin: 0 2.6rem !important;
            }
            &.ant-menu-item {
                height: 6rem !important;
                line-height: 6rem !important;
                @include xlg {
                    height: 7rem !important;
                    line-height: 7rem !important;
                }
                @include md {
                    height: 5rem !important;
                    line-height: 5rem !important;
                }
            }
            a {
                color: $gay-text !important;
            }
            &.ant-menu-item-selected {
                background: transparent !important;
            }
            .ant-menu-title-content {
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                a {
                    padding-left: 6.2rem;
                    @include xxl {
                        padding-left: 7rem;
                    }
                    @include md {
                        padding-left: 5rem;
                    }
                    &.active {
                        background: linear-gradient(309.43deg, #131620 -142.29%, #5d6e9e 78.58%) !important;
                        border: 0.770559px solid #7d8bb1;
                        box-sizing: border-box;
                        box-shadow: 0px 3px 26px rgba(19, 22, 32, 0.28);
                        border-radius: 1.4rem;
                        display: block;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            a {
                &:before {
                    content: "";
                    position: absolute;
                    width: 28px;
                    height: 28px;
                    z-index: 9;
                    background: url("../../img/sprite3x.png");
                    background-size: 1920px;
                    left: 2rem !important;
                }
                &.active {
                    font-weight: $font-bold;
                    color: $color-white !important;
                }
            }

            &:hover {
                background: rgba(0, 0, 0, 0.2);
            }
            &.dashboard {
                a {
                    &:before {
                        background-position: $dashboard;
                        top: calc(50% - 14px);
                    }
                    @include xxl {
                        &::before {
                            background-position: -237px -361px;
                            background-size: 1400px;
                            top: calc(50% - 14px);
                        }
                    }
                    &.active {
                        &:before {
                            background-position: $dashboardActive;
                        }
                        @include xxl {
                            &::before {
                                background-position: -168px -361px;
                            }
                        }
                    }
                }
            }
            &.demographic {
                a {
                    &:before {
                        background-position: $demographic;
                        top: calc(50% - 14px);
                    }
                    @include xxl {
                        &::before {
                            background-position: -236px -417px;
                            background-size: 1400px;
                            top: calc(50% - 14px);
                        }
                    }
                    &.active {
                        &:before {
                            background-position: $demographicActive;
                        }
                        @include xxl {
                            &::before {
                                background-position: -168px -417px;
                            }
                        }
                    }
                }
            }
            &.insurance {
                a {
                    &:before {
                        background-position: $insurance;
                        top: calc(50% - 14px);
                    }
                    @include xxl {
                        &::before {
                            background-position: -236px -473px;
                            background-size: 1400px;
                            top: calc(50% - 14px);
                        }
                    }
                    &.active {
                        &:before {
                            background-position: $insuranceActive;
                        }
                        @include xxl {
                            &::before {
                                background-position: -168px -473px;
                            }
                        }
                    }
                }
            }
            &.payment {
                a {
                    &:before {
                        background-position: $payment;
                        top: calc(50% - 14px);
                    }
                    @include xxl {
                        &::before {
                            background-position: -237px -528px;
                            background-size: 1400px;
                            top: calc(50% - 14px);
                        }
                    }
                    &.active {
                        &:before {
                            background-position: $paymentActive;
                        }
                        @include xxl {
                            &::before {
                                background-position: -168px -528px;
                            }
                        }
                    }
                }
            }
            &.document {
                a {
                    &:before {
                        background-position: $document;
                        top: calc(50% - 14px);
                    }
                    @include xxl {
                        &::before {
                            background-position: -237px -583px;
                            background-size: 1400px;
                            top: calc(50% - 14px);
                        }
                    }
                    &.active {
                        &:before {
                            background-position: $documentActive;
                        }
                        @include xxl {
                            &::before {
                                background-position: -169px -583px;
                            }
                        }
                    }
                }
            }
            &.prescription {
                a {
                    &:before {
                        background-position: $prescription;
                        top: calc(50% - 14px);
                    }
                    @include xxl {
                        &::before {
                            background-position: -237px -694px;
                            background-size: 1400px;
                            top: calc(50% - 14px);
                        }
                    }
                    &.active {
                        &:before {
                            background-position: $prescriptionActive;
                        }
                        @include xxl {
                            &::before {
                                background-position: -168px -694px;
                            }
                        }
                    }
                }
            }
            &.appointment {
                a {
                    &:before {
                        background-position: $appointment;
                        top: calc(50% - 14px);
                    }
                    @include xxl {
                        &::before {
                            background-position: -237px -639px;
                            background-size: 1400px;
                            top: calc(50% - 14px);
                        }
                    }
                    &.active {
                        &:before {
                            background-position: $appointmentActive;
                        }
                        @include xxl {
                            &::before {
                                background-position: -167px -639px;
                            }
                        }
                    }
                }
            }
            &.consent {
                a {
                    &:before {
                        background-position: $concent;
                        top: calc(50% - 14px);
                    }
                    @include xxl {
                        &::before {
                            background-position: -237px -750px;
                            background-size: 1400px;
                            top: calc(50% - 14px);
                        }
                    }
                    &.active {
                        &:before {
                            background-position: -168px -750px;
                        }
                    }
                }
            }
        }
        &.ant-menu-inline-collapsed {
            li {
                span {
                    a {
                        span {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    .log-out {
        background: linear-gradient(0deg, rgba(190, 197, 216, 0.3), rgba(190, 197, 216, 0.3));
        border-radius: 1.6rem;
        height: 6rem;
        position: absolute;
        bottom: 6rem;
        left: 2.9rem;
        color: $gay-text;
        font-weight: $font-semi-bold;
        font-size: $font-size-ml;
        width: calc(100% - 5.8rem);
        cursor: pointer;
        border: none;
        outline: none;
        padding-left: 6.4rem;
        text-align: left;
        @include md {
            bottom: 4rem;
            padding-left: 5.4rem;
        }
        &::before {
            background-position: $logout;
            width: 24px;
            height: 24px;
            position: absolute;
            top: calc(50% - 1.2rem);
            left: 2rem;
        }
        @include xxl {
            &::before {
                top: calc(50% - 1.8rem);
            }
        }
        @include md {
            &::before {
                top: calc(50% - 1.2rem);
            }
        }
    }
}

.mobile-logo {
    display: none;
}

@include xs() {
    .mobile-logo {
        display: block;
    }
}
