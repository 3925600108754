@import "../../css/mixin.scss";
@import "../../css/vars.scss";
@import "../../css/breakpoints.scss";

.appointment-main-wrap {
    background: $light-gray;
    padding: 3rem 2rem 4rem;
    box-shadow: -10px -10px 30px #ffffff, 10px 10px 30px rgba(223, 226, 236, 0.4);
    border-radius: $gap-12;
    @include md {
        padding: 1.6rem 0.8rem 3rem;
    }
    .appointment-title-head {
        @include flexbox();
        @include justify-content(space-between);
        @include align-items(center);
        margin-bottom: $gap-16;
        @include md {
            @include flex-direction(column-reverse);
            @include align-items(flex-start);
            button {
                width: calc(100% - 48px);
                margin-left: 24px;
            }
            .title {
                font-size: 2rem;
                font-weight: $font-bold;
                margin-top: 3.2rem;
                margin-left: 0.8rem;
            }
        }
    }
}
