@import "../../css/breakpoints.scss";
@import "../../css/vars.scss";

.map-area {
    @include md {
        min-height: 37rem;
    }
    .mobile-img {
        display: none;
        @include md {
            display: block;
        }
    }
    .desktop-img {
        display: block;
        @include md {
            display: none;
        }
    }
}
