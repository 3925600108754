@import "../../css/vars.scss";
@import "../../css/sprite-positions.scss";
@import "../../css/breakpoints.scss";

.ant-collapse {
    border: none;
    background: transparent;
    .ant-collapse-item {
        border-bottom: none;
        margin-bottom: $gap-08;
        .ant-collapse-header {
            background: $sky-color;
            box-shadow: 0px 1px 4px rgba(19, 22, 32, 0.08);
            border-radius: $border-radius-base + 0.4rem;
            height: 6rem;
            font-size: $font-size-1xl;
            color: $heading-color;
            font-weight: $font-semi-bold;
            padding: 1.5rem 2.4rem 1.5rem 1.5rem;
            line-height: 2.8rem;
            @include md {
                font-size: 2.2rem;
            }
            .ant-collapse-arrow {
                background: url("../../img/sprite3x.png");
                background-size: 1920px;
                background-position: $arrowUp;
                top: calc(50% - 1.7rem);
                position: absolute;
                width: 24px;
                height: 24px;
                @include xxl {
                    background-position: -118px -64px;
                    background-size: 1200px;
                    position: absolute;
                    width: 14px;
                    height: 20px;
                }
                svg {
                    display: none;
                }
            }
            span {
                position: absolute;
                right: 0;
                top: 50%;
            }
        }
        &.ant-collapse-item-active {
            .ant-collapse-header {
                background: $light-sky;
                .ant-collapse-arrow {                    
                    top: calc(50% - 0.6rem);
                    transform: rotate(180deg);
                    @include xxl{
                        top: calc(50% - 0.9rem);
                    }
                }
            }
        }
        .ant-collapse-content {
            border-top: none;
        }
    }
}
