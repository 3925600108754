@import "../../css/mixin.scss";
@import "../../css/vars.scss";
@import "../../css/breakpoints.scss";

.ocular-heath-wrap{
    @include flexbox();
    .ocular-heath-desktop-wrap{
        @include flexbox();
    }
    @include md {
        display: block;
        margin: 0 -1.2rem 3rem;
    }
    .vision-section{
        @include flex(1);
    }
    .pressure-section{
        @extend .vision-section;
    }
}
@media (min-width: 768px){
    .desktop-only{
        display: block !important;
    }
    .mobile-only{
        display: none !important;
    }
}

@media (max-width: 767px){
    .mobile-only{
        display: block !important
    }
    .desktop-only{
        display: none !important
    }
}