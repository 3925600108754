@import "../../css/vars.scss";
@import "../../css/mixin.scss";
@import "../../css/breakpoints.scss";

.welcome-card {
    background: $light-gray;
    padding: 2.4rem 2.5rem;
    border-radius: $border-radius-base + 0.6rem;
    position: relative;
    overflow: hidden;
    @include md {
        padding: 2.4rem 1.2rem;
    }
    &::before {
        content: "";
        background: url("../../img/welcome-bg-1.svg");
        position: absolute;
        top: 0px;
        right: -12px;
        width: 74px;
        height: 62px;
    }
    &::after {
        content: "";
        background: url("../../img/welcome-bg-2.svg");
        position: absolute;
        bottom: 0;
        left: 0px;
        width: 50px;
        height: 30px;
    }
    .user-profile-wrap {
        @include flexbox();
        .img-area {
            margin-right: 2.5rem;
            width: 11.8rem;
            height: 11.8rem;
            @include md {
                margin-right: 0.8rem;
                width: 8.8rem;
                height: 8.8rem;
            }
            img {
                max-width: 100%;
            }
        }
        .user-details {
            width: 100%;
            .welcome-massage {
                padding-right: 110px;
                letter-spacing: 0.2rem;
                @include md {
                    padding-right: 0;
                    letter-spacing: 1.2px;
                    span {
                        &.bold {
                            display: block;
                            @include md {
                                font-size: 1.8rem;
                                letter-spacing: 0.5px;
                            }
                        }
                    }
                }
            }
            .location {
                margin-top: 8px;
            }
            .notify-text-button {
                margin-top: $gap-16;
                @include flexbox();
                @include align-items(center);
                @include justify-content(space-between);
                @include md {
                    display: block;
                    margin-left: -83px;
                    text-align: center;
                }
                .notify-text {
                    font-weight: $font-regular;
                    font-size: $font-size-1xl;
                    @include md {
                        line-height: 3.6rem;
                        margin: 1.6rem 0 1.6rem 0;
                        font-size: $font-size-base;
                    }
                }
            }
        }
    }
}
