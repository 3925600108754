@import "../../css/mixin.scss";
@import "../../css/vars.scss";
@import "../../css/sprite-positions.scss";
@import "../../css/breakpoints.scss";

.insurance-main-wrap {
    background: $light-gray;
    padding: 3rem 2rem;
    border-radius: $gap-12;
    box-shadow: -10px -10px 30px #ffffff, 10px 10px 30px rgba(223, 226, 236, 0.4);
    @include md {
        padding: 1.8rem 1.6rem;
    }
    .insurance-wrap-in {
        @include flexbox();
        @include align-items(flex-start);
        @include md {
            display: block;
            position: relative;
        }
        .ant-carousel .slick-prev,
        .ant-carousel .slick-next {
            top: calc(50% - 2rem);
            @include md {
                top: calc(50% - 3.5rem);
            }
        }
        .carousel-img {
            .ant-carousel {
                .slick-prev {
                    left: -6px;
                }
                .slick-next {
                    right: -6px;
                }
                .slick-dots {
                    opacity: 0;
                }
            }
        }
        h2 {
            margin-bottom: 2.2rem;
            @include md {
                font-size: 2rem;
            }
        }
        .insurance-form-wrap {
            width: 100%;
            position: relative;
            margin-left: 4rem;
            padding-right: 50px;
            @include md {
                margin: 0;
                padding-right: 0;
                position: static;
            }
            h2 {
                margin-bottom: $gap-16;
            }
            .view-mode-section {
                .custom-text-input {
                    input {
                        color: $heading-color;
                    }
                }
            }
            .non-editable {
                .custom-text-input {
                    input {
                        pointer-events: none;
                        border: none;
                        background: transparent;
                        font-weight: $font-semi-bold;
                        padding: 0;
                        line-height: 2.3rem;
                    }
                }
            }
            .separator {
                &.dotted-line {
                    width: calc(100% - 7.7rem);
                    margin: 4rem 0 4rem 0rem;
                }
            }

            .insurance-action-btn {
                position: absolute;
                top: 0;
                right: 2.5rem;
                @include md {
                    right: 1.5rem;
                }
                button {
                    width: 30px;
                    cursor: pointer;
                    &.edit-icon {
                        &:before {
                            background-position: $edit;
                            width: 30px;
                            height: 30px;
                            position: absolute;
                            top: -20px;
                            right: -4px;
                        }
                        &:before {
                            background-position: $edit-md;
                            background-size: 1600px;
                        }
                    }
                    &.save-icon {
                        &:before {
                            background: url("../../img/check-tick.png");
                            background-repeat: no-repeat;
                            width: 30px;
                            height: 30px;
                            position: absolute;
                            top: -20px;
                        }
                    }
                    &.user-icon {
                        &:before {
                            background-position: $userIcon;
                            width: 30px;
                            height: 30px;
                            position: absolute;
                            top: -20px;
                            right: -20px;
                        }
                        @include xxl {
                            &:before {
                                background-position: $userIcon-md;
                                background-size: 1600px;
                            }
                        }
                    }
                }
            }
        }
        .form-container {
            .row-wrap {
                @include flexbox();
                @include md {
                    display: block;
                }
                .first-col {
                    margin-right: 4rem;
                    @include flex(1);
                    @include md {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}
