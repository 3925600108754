// sprite icons position

$dashboardActive: -229px -501px; // Icon for Dashboard active

$dashboard: -323px -501px; // Icon for Dashboard

$demographicActive: -229px -501px; // Icon for Demographic active

$demographic: -323px -577px; // Icon for Demographic

$insuranceActive: -229px -501px; // Icon for insurance active

$insurance: -323px -653px; // Icon for insurance 

$paymentActive: -229px -501px; // Icon for financial

$payment: -323px -729px; // Icon for financial

$documentActive: -229px -501px; // Icon for document active

$document: -323px -805px; // Icon for document

$appointmentActive: -229px -501px; // Icon for appointment active

$appointment: -323px -882px; // Icon for appointment

$prescriptionActive: -229px -501px; // Icon for encounter active

$prescription: -323px -957px; // Icon for encounter

$concentActive: -229px -501px; // Icon for concent-icon

$concent: -323px -1033px; // Icon for concent-icon

$arrowUp: -188px -105px; // Icon for arrow up

$time: -116px -107px; // Icon for time

$notification: -192px -185px; // Icon for notification

$logout: -325px -407px; // Icon for logout

$check: -257px -256px; // Icon for Check

$edit: -390px -653px; // Icon for edit

$edit-md: -321px -542px; // Icon for edit

$back: -116px -326px; // Back arrow

$userIcon: -390px -729px; // User icon

$userIcon-md: -323px -605px; // User icon