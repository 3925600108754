@import "../../../../css/mixin.scss";
@import "../../../../css/vars.scss";
@import "../../../../css/sprite-positions.scss";
@import "../../../../css/breakpoints.scss";

.slide-cards-wrap {
  &.show {
    .flip-card-inner {
      transform: rotateY(180deg);
    }
  }
}

.flip-card {
  background-color: transparent;
  width: auto;
  height: 22rem;
  perspective: 1000px;
  @include flexbox();
  @include flex-direction(column);
  @include md {
    height: 25rem;
  }
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  color: black;
}

.flip-card-back {
  background-color: $secondary-color;
  color: $color-white;
  transform: rotateY(180deg);
  border-radius: 1.6rem;
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);
  h1 {
    color: $color-white;
  }
}

.view-back {
  text-align: center;
  margin-top: 1.6rem;
  font-size: $font-size-xl;
  font-weight: $font-medium;
  cursor: pointer;
  @include md {
    margin-bottom: 3.4rem;
  }
  span {
    position: relative;
    padding-right: 3.4rem;
    &::before {
      background-position: $back;
      width: 24px;
      height: 30px;
      position: absolute;
      top: 0px;
      right: 0;
    }
    @include xxl {
      &::before {
        background-position: -94px -273px;
        background-size: 1600px;
        width: 24px;
        height: 24px;
        top: -2px;
        right: -6px;
      }
    }
    @include md {
      text-decoration: underline;
      &::before {
        top: 4px;
        right: 3px;
      }
    }
  }
}
