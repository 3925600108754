@import "../../css/sprite-positions.scss";
@import "../../css/mixin.scss";
@import "../../css/vars.scss";
@import "../../css/breakpoints.scss";

.current-balance-wrap {
    background: url("../../img/current-blc.png");
    border-radius: $border-radius-base + 0.8rem;
    padding: 3rem;
    height: calc(100% - 33px);
    @include flexbox();
    @include align-items(center);
    @include md {
        height: 42.8rem;
        padding-bottom: 0;
    }
    .current-balance {
        text-align: center;    
        h2{
            font-size: $font-size-1xl;
        }    
        p{
            font-size: $font-size-1xl;
            font-weight: $font-medium;
        }
        .balance-btn {
            margin-top: 3.0rem;
            @include flexbox();
            @include md {
                margin-top: 7.6rem;
                @include flex-wrap(wrap);
            }
            button {
                margin-right: 1.6rem;
                @include md {
                    margin-right: 0;
                    margin-bottom: 1.6rem;
                    width: 100%;
                }
                &+ button {
                    margin-right: 0;
                }
            }
        }
        h1{
            margin-bottom: 1.6rem;
            line-height: 4.5rem;
        }
        h2{
            margin-bottom: 0.8rem;
        }
    }
}
