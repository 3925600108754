@import "../../css/mixin.scss";
@import "../../css/vars.scss";
@import "../../css/breakpoints.scss";

.filter-wrap {
    .ant-select {
        &:not(.ant-select-customize-input) {
            .ant-select-selector {
                border-radius: $gap-10;
                border: none;
                color: #8e9abb;
                font-size: 1.8rem;
                font-weight: $font-semi-bold;
                background: $color-white;
                line-height: 1.8rem;
                height: 4.8rem;
                @include md {
                    border: none;
                    padding: 0;
                    height: 2.4rem;
                    .ant-select-selection-item {
                        display: none;
                    }
                }
            }
        }
        &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
            .ant-select-selector {
            box-shadow: none;
            border-color: $heading-color;
        }
        .ant-select-arrow {
            background: url("../../img/sprite3x.png");
            background-size: 1920px;
            background-position: -259px -114px;
            top: calc(50% - 1px);
            position: absolute;
            width: 16px;
            height: 16px;
            @include xxl {
                background-position: -158px -68px;
                background-size: 1200px;
            }
            @include md {
                background-position: -393px -1035px;
                top: 7px;
                left: 8px;
                height: 2.4rem;
                width: 2.4rem;
            }
            svg {
                display: none;
            }
        }
        @include md {
            width: 30px !important;
        }
    }
}

.ant-select-dropdown {
    width: 200px !important;
}
