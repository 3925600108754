@import "../../css/mixin.scss";
@import "../../css/vars.scss";
@import "../../css/breakpoints.scss";

.table-wrap {
    table {
        width: 100%;
        thead {
            background: $sky-color;
            height: 4rem;
            border-radius: $gap-10;
            box-shadow: 0px 1px 4px rgba(19, 22, 32, 0.08);
            overflow: hidden;
            tr {
                th {
                    text-align: left;
                    padding-left: 6rem;
                    font-size: $font-size-ml;
                    line-height: 2.3rem;
                    font-weight: $font-bold;
                    color: $heading-color;
                    height: 4rem;
                    @include md {
                        padding-left: 2rem;
                        font-size: 1.6rem;
                        height: 6.4rem;
                    }
                    &:first-child {
                        border-radius: 1rem 0 0 1rem;
                    }
                    &:last-child {
                        border-radius: 0 1rem 1rem 0;
                        padding-right: 1.6rem;
                    }
                }
            }
        }
        tbody {
            tr {
                background: $color-white;
                height: 4rem;
                border-radius: $gap-08;
                box-shadow: 0px 1px 4px rgba(19, 22, 32, 0.08);
                overflow: hidden;
                &:nth-child(even){
                    background: $light-gray;
                }
                td {
                    text-align: left;
                    padding-left: 6rem;
                    font-size: $font-size-ml;
                    color: $heading-color;
                    font-weight: $font-medium;
                    height: 4rem;
                    @include md {
                        padding-left: 2rem;
                        font-size: 1.6rem;
                    }
                    &:first-child {
                        border-radius: 0.8rem 0 0.8rem;
                    }
                    &:last-child {
                        border-radius: 0 0.8rem 0.8rem 0;
                    }
                }
            }
        }
    }
}
